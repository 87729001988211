import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import axios from "axios";
import './App.css';

import DefaultComponent from "./Components/DefaultComponent";
import ContentEditor from "./Components/ContentEditor";
import Alert from "./Components/Alert";

const { LOCALHOST } = process.env;

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Route exact path="/" component={ContentEditor} />
        <Route path="/alert" component={Alert} />
      </HashRouter>
    </div>
  );
}

export default App;
