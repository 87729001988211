import React, { useState, useEffect } from "react";
import { useStyles } from "react-styles-hook";
import axios from "axios";

import { Button } from "react-bootstrap";

const { LOCALHOST } = process.env;

function Alert() {
  const [alertTitle, setAlertTitle] = useState('');
  const [showAlert, setShowAlert] = useState(0);
  const [showAlertText, setShowAlertText] = useState('');
  
  useEffect(() => {
    console.log("useEffect fired!");
    axios.defaults.baseURL = LOCALHOST;

    console.log(window.location.href);
    let urlElements = window.location.href.split("=" || "#");

    console.log("urlElements: ", urlElements);
    console.log(urlElements[1], urlElements[2]);

    axios
      .get(`api/database/${urlElements[1]}/${urlElements[2]}`)
      .then((res) => {
        console.log(res);

        axios
          .get(`api/alert`)
          .then(res => {
            console.log("res.data: ", res.data[0]);
            setAlertTitle(res.data[0].alert_title);
            
            let bodyHtml = document.getElementById('body-input');
            bodyHtml.innerHTML = res.data[0].alert_body;
            if(res.data[0].show_alert == 0) {
              setShowAlertText("Turn Website Alert On");
            } else {
              setShowAlertText("Turn Website Alert Off");
            }
          })
          .catch(err => console.log(err))
      })
      .catch((err) => console.log(err));
  }, []);

  function handleShowAlert() {
    if(showAlert == 0) {
      handlePost(1);
      setShowAlert(1);
      setShowAlertText("Turn Website Alert Off");

      let bodyHtml = document.getElementById('body-input');
      console.log("handleShowAlert fired: ", bodyHtml.innerHTML);
      axios
        .post(`api/UpdateAlert`, {alert_id: 1, alert_title: alertTitle, alert_body: bodyHtml.innerHTML, show_alert: 1})
        .then(res => console.log(res))
        .catch(err => console.log(err))

    } else {
      handlePost(0);
      setShowAlert(0);
      setShowAlertText("Turn Website Alert On");
    }
  }

  function handlePost(show_alert) {
    let bodyHtml = document.getElementById('body-input');
    console.log("handlePost fired: ", bodyHtml);
    axios
      .post(`api/UpdateAlert`, {alert_id: 1, alert_title: alertTitle, alert_body: bodyHtml.innerHTML, show_alert: show_alert})
      .then(res => {
        console.log("res.data", res.data);
        if(res.data === "Update Successful!" && show_alert == 0) {
          alert("Website Alert has successfully turned OFF. \nWebsite Alert will NOT display on your home page.");
        } else if(res.data === "Update Successful!" && show_alert == 1) {
          alert("Website Alert has successfully turned ON. \nWebsite Alert will now display on your home page.")
        } else {
          alert('Your Website Alert did not save successfully. Please try clicking the "Turn Website Alert On/Off" button again.')
        }
      })
      .catch(err => console.log(err))
  }

  function richTextEditor(param) {
    console.log("richTextEditor function fired!", param);

    if (param == "createlink") {
      let url = prompt("Enter the link here: ", "https://");
      document.execCommand(param, false, url);
    } 

    else document.execCommand(param, false, null);
  }

  return (
    <div id="page" style={styles.page}>
        <h4 class='mb-4' style={styles.header}>This alert message will pop-up on the home page of your site once you click the 'Turn Website Alert On' button.</h4>
        <br/>
        <p class='mb-1'><strong>Alert Title: </strong></p>
        <input style={styles.titleInput} class='mb-0' onChange={(e) => setAlertTitle(e.target.value)} value={alertTitle} />
        <br/>
        <p class='mb-1'><strong>Alert Body: </strong></p>
        <div style={styles.toolbar}>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => {richTextEditor("bold")}}
            >
              <i className="fa fa-bold"></i>
            </button>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => {richTextEditor("italic")}}
            >
              <i className="fa fa-italic"></i>
            </button>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => {richTextEditor("underline")}}
            >
              <i className="fa fa-underline"></i>
            </button>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => {richTextEditor("createlink")}}
            >
              <i className="fa fa-link"></i>
            </button>
        </div>
        <p id='body-input' style={styles.bodyInput} class='mb-4' contentEditable={true}></p><br/>
        <Button id='publish-button' class='btn-large' onClick={() => handleShowAlert()}>{showAlertText}</Button><br/> 
    </div>
  );
}

export default Alert;

let styles = useStyles({
  header: {
    color: "black",
  },
  page: {
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 1px 2px 1px rgba(0,0,0,.08), 0 3px 6px rgba(0,0,0,.08)",
    padding: "50px"
  },
  titleInput: {
    backgroundColor: "#fff",
    padding:"20px 15px",
    boxShadow: "0px 0px 4px 1px #cacaca",
    borderRadius: "3px",
    border: 0,
  },
  toolbar: {
    margin: "15px 5px 15px 0",
    display: "flex",
    alignItems: "center",
  },
  toolbarGroupItem: {
    width: 40,
    margin: "0 10px 0 0",
    color: "black",
    cursor: "pointer",
    backgroundColor: "rgb(222 222 222)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 1px 2px 1px rgba(0,0,0,.6)",
    padding: "5px 10px"
  },
  bodyInput: {
    backgroundColor: "#fff",
    padding: "20px 15px",
    boxShadow: "0px 0px 4px 1px #cacaca",
    borderRadius: "3px",
  }
});
