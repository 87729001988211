import React, { useState, useEffect } from "react";
import { useStyles } from "react-styles-hook";
import axios from "axios";

import { Button } from "react-bootstrap";

const { LOCALHOST } = process.env;
let currentId = "";
let index = 0;

function ContentEditor() {
  const [databaseName, setDatabaseName] = useState("");
  const [displayPlusButton, setDisplayPlusButton] = useState(true);
  const [displayAddRowBox, setDisplayAddRowBox] = useState(false);
  const [displayAddRowBox2, setDisplayAddRowBox2] = useState(false);
  const [displayContent, setDisplayContent] = useState(true);
  const [displayFileUploadButton, setDisplayFileUploadButtion] = useState(true);
  const [displayHtmlRow, setDisplayHtmlRow] = useState(false);
  const [displayUploadButton, setDisplayUploadButtion] = useState(true);
  const [fileURL, setFileURL] = useState("");
  const [ftbNum, setFtbNum] = useState("");
  const [html, setHtml] = useState([]);
  const [intro, setIntro] = useState("intro");
  const [imgURL, setImgURL] = useState("");
  const [rowType, setRowType] = useState("");
  const [sizeSelectValue, setSelectValue] = useState(0);
  const [headerSelectValue, setHeaderSelectValue] = useState(0);

  const [files, setFiles] = useState([]);
  const [showFiles, setShowFiles] = useState(false);

  useEffect(() => {
    console.log("useEffect fired!");
    axios.defaults.baseURL = LOCALHOST;

    console.log(window.location.href);
    let urlElements = window.location.href.split("=" || "#");

    console.log("urlElements: ", urlElements);

    setFtbNum(urlElements[1]);
    setDatabaseName(urlElements[2]);
    urlElements[3] = urlElements[3].slice(0, urlElements[3].length - 2);
    console.log(urlElements[1], urlElements[2], urlElements[3]);

    axios
      .get(`api/database/${urlElements[2]}/${urlElements[3]}`)
      .then((res) => {
        console.log(res);
        axios
          .get(`/api/content/${urlElements[1]}`)
          .then((res) => {
            if (!res.data) {
              console.log("no res.data");
            } else {
              let result = res.data;

              result = result.replace(/class="col/g, 'contenteditable="true" class="col');
              result = result.replace(/<section>/g, function (match) {
                index = index + 1;
                return (index === index)
                  ? `<section id=${index}>
                        <button class="up-button" onclick="(function(elem){ 
                          let id = elem.parentNode.id;

                          let moveToOldValue = document.getElementById(id - 1).innerHTML;
                          let currentValue = document.getElementById(id).innerHTML;

                          document.getElementById(id - 1).innerHTML = currentValue;
                          document.getElementById(id).innerHTML = moveToOldValue;
                          
                        })(this);">
                          &uarr; Move Row Up
                        </button>
                        <button class="down-button" onclick="(function(elem){ 
                          let id = elem.parentNode.id;

                          let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                          let currentValue = document.getElementById(id).innerHTML;

                          document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                          document.getElementById(id).innerHTML = moveToOldValue;

                        })(this);">
                          &darr; Move Row Down
                        </button>
                        <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>`
                  : match;
              })
              result = result.replace(/cms-img-col"/g, function (match) {
                index = index + 1;
                return (index === index) ? `cms-img-col" id=${index + "c"}`
                  : match;
              })
              result = result.replace(/cms-back-img-col"/g, function (match) {
                index = index + 1;
                return (index === index) ? `cms-back-img-col" id=${index + "cb"}`
                  : match;
              })
              console.log("result: ", result);

              setHtml([result]);
            }
          })
          .catch((err) => {
            console.log(err);

            backupConnection();
          });
      })
      .catch((err) => { console.log(err); backupConnection(); });
  }, []);

  function backupConnection() {
    console.log("backupConnection fired!")
    console.log("useEffect fired!");
    axios.defaults.baseURL = LOCALHOST;

    console.log(window.location.href);
    let urlElements = window.location.href.split("=" || "#");

    console.log("urlElements: ", urlElements);

    setFtbNum(urlElements[1]);
    setDatabaseName(urlElements[2]);
    urlElements[3] = urlElements[3].slice(0, urlElements[3].length - 2);
    console.log(urlElements[1], urlElements[2], urlElements[3]);

    axios
      .get(`api/database/${urlElements[2]}/${urlElements[3]}`)
      .then((res) => {
        console.log(res);
        axios
          .get(`/api/content/${urlElements[1]}`)
          .then((res) => {
            if (!res.data) {
              console.log("no res.data");
            } else {
              let result = res.data;

              result = result.replace(/class="col/g, 'contenteditable="true" class="col');
              result = result.replace(/<section>/g, function (match) {
                index = index + 1;
                return (index === index)
                  ? `<section id=${index}>
                        <button class="up-button" onclick="(function(elem){ 
                          let id = elem.parentNode.id;

                          let moveToOldValue = document.getElementById(id - 1).innerHTML;
                          let currentValue = document.getElementById(id).innerHTML;

                          document.getElementById(id - 1).innerHTML = currentValue;
                          document.getElementById(id).innerHTML = moveToOldValue;
                          
                        })(this);">
                          &uarr; Move Row Up
                        </button>
                        <button class="down-button" onclick="(function(elem){ 
                          let id = elem.parentNode.id;

                          let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                          let currentValue = document.getElementById(id).innerHTML;

                          document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                          document.getElementById(id).innerHTML = moveToOldValue;

                        })(this);">
                          &darr; Move Row Down
                        </button>
                        <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>`
                  : match;
              })
              result = result.replace(/cms-img-col"/g, function (match) {
                index = index + 1;
                return (index === index) ? `cms-img-col" id=${index + "c"}`
                  : match;
              })
              result = result.replace(/cms-back-img-col"/g, function (match) {
                index = index + 1;
                return (index === index) ? `cms-back-img-col" id=${index + "cb"}`
                  : match;
              })
              console.log("result: ", result);

              setHtml([result]);
            }
          })
          .catch((err) => {
            console.log(err);

            backupConnection();
          });
      })
      .catch((err) => { console.log(err); backupConnection(); });
  }

  function seeFiles() {
    axios.defaults.baseURL = LOCALHOST;

    console.log(window.location.href);
    let urlElements = window.location.href.split("=" || "#");

    console.log("urlElements: ", urlElements);

    axios
      .get(`/api/files/${urlElements[2]}`)
      .then(res => {
        setFiles(res.data);
        setShowFiles(true);
      })
      .catch(err => console.log(err))
  }

  function addRow(colNum, colType) {
    console.log("addTextRow fired!");

    index = index + 1;

    setHtml([document.getElementById("page-html").innerHTML]);

    if (colNum == 1 && intro == "intro" && colType == "intro") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="intro-section" style="margin-bottom: 0;">
                  <div class="container pad-container">
                    <div class="row">
                      <div class="col-md-12 cms-col intro-col" contenteditable="true">

                      </div>
                    </div> 
                  </div>
                </div>
              </section> 
              `,
      ]);
    }

    if (colNum == 1 && rowType == "pad" && colType == "text-only") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="container pad-container">
                  <div class="row">
                    <div class="col-md-12 cms-col col-text" contenteditable="true">
                    
                    </div>
                  </div> 
                </div>
              </section> 
              `,
      ]);
    } else if (colNum == 1 && rowType == "full" && colType == "text-only") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="container-fluid full-container">
                  <div class="row">
                    <div class="col-md-12 cms-col col-text" contenteditable="true">
                      
                    </div>
                  </div>
                </div>
              </section>  
              `,
      ]);
    }

    if (colNum == 1 && rowType == "pad" && colType == "img-only") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="container pad-container">
                  <div class="row">
                    <div id=${index + "c"} class="col-md-12 cms-img-col" contenteditable="true" onkeydown="(function(event) { console.log('Code: ', event.keyCode); if(event.keyCode !== 8) { event.preventDefault(); alert('This is an image column. It cannot accept text. Please Upload Image.')} })(event);">
                      Insert image here...
                    </div>
                  </div> 
                </div>
              </section> 
              `,
      ]);
    } else if (colNum == 1 && rowType == "full" && colType == "img-only") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="container-fluid full-container">
                  <div class="row">
                    <div id=${index + "c"} class="col-md-12 cms-img-col" contenteditable="true" onkeydown="(function(event) { console.log('Code: ', event.keyCode); if(event.keyCode !== 8) { event.preventDefault(); alert('This is an image column. It cannot accept text. Please Upload Image.')} })(event);">
                      Insert image here...
                    </div>
                  </div>
                </div>
              </section>  
              `,
      ]);
    }

    if (colNum == 2 && rowType == "pad" && colType == "text-img") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="container pad-container">
                  <div class="row">
                    <div class="col-md-6 cms-col col-text-left" contenteditable="true">
                      
                    </div>
                    <div id=${index + "c"} class="col-md-6 img-col-6 cms-img-col" contenteditable="true" onkeydown="(function(event) { console.log('Code: ', event.keyCode); if(event.keyCode !== 8) { event.preventDefault(); alert('This is an image column. It cannot accept text. Please Upload Image.')} })(event);">
                      Insert image here...
                    </div>
                  </div>  
                </div>
              </section>
              `,
      ]);
    } else if (colNum == 2 && rowType == "full" && colType == "text-img") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="container-fluid full-container">
                  <div class="row">
                    <div class="col-md-6 cms-col col-text-left" contenteditable="true">
                      
                      <div></div>
                    </div>
                    <div id=${index + "c"} class="col-md-6 img-col-6 cms-img-col" contenteditable="true" onkeydown="(function(event) { console.log('Code: ', event.keyCode); if(event.keyCode !== 8) { event.preventDefault(); alert('This is an image column. It cannot accept text. Please Upload Image.')} })(event);">
                      Insert image here...
                    </div>
                  </div>  
                </div>
              </section>
              `,
      ]);
    }

    if (colNum == 2 && rowType == "pad" && colType == "img-text") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="container pad-container">
                  <div class="row">
                    <div id=${index + "c"} class="col-md-6 img-col-6 cms-img-col" contenteditable="true" onkeydown="(function(event) { console.log('Code: ', event.keyCode); if(event.keyCode !== 8) { event.preventDefault(); alert('This is an image column. It cannot accept text. Please Upload Image.')} })(event);">
                      Insert image here...
                    </div>
                    <div class="col-md-6 cms-col col-text-right" contenteditable="true">
                      
                    </div>
                  </div>  
                </div>
              </section>
              `,
      ]);
    } else if (colNum == 2 && rowType == "full" && colType == "img-text") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="container-fluid full-container">
                  <div class="row">
                    <div id=${index + "c"} class="col-md-6 img-col-6 cms-img-col" contenteditable="true" onkeydown="(function(event) { console.log('Code: ', event.keyCode); if(event.keyCode !== 8) { event.preventDefault(); alert('This is an image column. It cannot accept text. Please Upload Image.')} })(event);">
                      Insert image here...
                    </div>
                    <div class="col-md-6 cms-col col-text-right" contenteditable="true">
                      
                    </div>
                  </div>  
                </div>
              </section>
              `,
      ]);
    }

    if (colNum == 2 && rowType == "pad" && colType == "text-text") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="container pad-container">
                  <div class="row">
                    <div class="col-md-6 cms-col col-text-left" contenteditable="true">
                      
                    </div>
                    <div class="col-md-6 cms-col col-text-right" contenteditable="true">
                      
                    </div>
                  </div>  
                </div>
              </section>
              `,
      ]);
    } else if (colNum == 2 && rowType == "full" && colType == "text-text") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="container-fluid full-container">
                  <div class="row">
                    <div class="col-md-6 cms-col col-text-left" contenteditable="true">
                      
                    </div>
                    <div class="col-md-6 cms-col col-text-right" contenteditable="true">
                      
                    </div>
                  </div>  
                </div>
              </section>
              `,
      ]);
    }

    if (colNum == 2 && rowType == "pad" && colType == "img-img") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="container pad-container">
                  <div class="row">
                    <div id=${index + "c"} class="col-md-6 img-col-6 cms-img-col" contenteditable="true" onkeydown="(function(event) { console.log('Code: ', event.keyCode); if(event.keyCode !== 8) { event.preventDefault(); alert('This is an image column. It cannot accept text. Please Upload Image.')} })(event);">
                      Insert image here...
                    </div>
                    <div id=${index + "c2"} class="col-md-6 img-col-6 cms-img-col" contenteditable="true" onkeydown="(function(event) { console.log('Code: ', event.keyCode); if(event.keyCode !== 8) { event.preventDefault(); alert('This is an image column. It cannot accept text. Please Upload Image.')} })(event);">
                      Insert image here...
                    </div>
                  </div>  
                </div>
              </section>
              `,
      ]);
    } else if (colNum == 2 && rowType == "full" && colType == "img-img") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="container-fluid full-container">
                  <div class="row">
                    <div id=${index + "c"} class="col-md-6 img-col-6 cms-img-col" contenteditable="true" onkeydown="(function(event) { console.log('Code: ', event.keyCode); if(event.keyCode !== 8) { event.preventDefault(); alert('This is an image column. It cannot accept text. Please Upload Image.')} })(event);">
                      Insert image here...
                    </div>
                    <div id=${index + "c2"} class="col-md-6 img-col-6 cms-img-col" contenteditable="true" onkeydown="(function(event) { console.log('Code: ', event.keyCode); if(event.keyCode !== 8) { event.preventDefault(); alert('This is an image column. It cannot accept text. Please Upload Image.')} })(event);">
                      Insert image here...
                    </div>
                  </div>  
                </div>
              </section>
              `,
      ]);
    }

    if (colNum == 2 && rowType == "pad" && colType == "text-backImg") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="container pad-container">
                  <div class="row">
                    <div class="col-md-6 cms-col col-text-left" contenteditable="true">
                        
                    </div>
                    <div id=${index + "cb"} class="col-md-6 img-col-6 cms-back-img-col" contenteditable="true" onkeydown="(function(event) { console.log('Code: ', event.keyCode); if(event.keyCode !== 8) { event.preventDefault(); alert('This is an image column. It cannot accept text. Please Upload Image.')} })(event);">
                      Insert background image here...
                    </div>
                  </div>  
                </div>
              </section>
              `,
      ]);
    } else if (colNum == 2 && rowType == "full" && colType == "text-backImg") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="container-fluid full-container">
                  <div class="row">
                    <div class="col-md-6 cms-col col-text-left" contenteditable="true">
                        
                    </div>
                    <div id=${index + "cb"} class="col-md-6 img-col-6 cms-back-img-col" contenteditable="true" onkeydown="(function(event) { console.log('Code: ', event.keyCode); if(event.keyCode !== 8) { event.preventDefault(); alert('This is an image column. It cannot accept text. Please Upload Image.')} })(event);">
                      Insert background image here...
                    </div>
                  </div>  
                </div>
              </section>
              `,
      ]);
    }

    if (colNum == 2 && rowType == "pad" && colType == "backImg-text") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="container pad-container">
                  <div class="row">
                    <div id=${index + "cb"} class="col-md-6 img-col-6 cms-back-img-col" contenteditable="true" onkeydown="(function(event) { console.log('Code: ', event.keyCode); if(event.keyCode !== 8) { event.preventDefault(); alert('This is an image column. It cannot accept text. Please Upload Image.')} })(event);">
                      Insert background image here...
                    </div>
                    <div class="col-md-6 cms-col col-text-right" contenteditable="true">
                      
                    </div>
                  </div>  
                </div>
              </section>
              `,
      ]);
    } else if (colNum == 2 && rowType == "full" && colType == "backImg-text") {
      setHtml((oldArray) => [
        ...oldArray,
        `
              <section id=${index}>
                 <button class="up-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(id - 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(id - 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;
                      
                    })(this);">
                      &uarr; Move Row Up
                    </button>
                    <button class="down-button" onclick="(function(elem){ 
                      let id = elem.parentNode.id;

                      let moveToOldValue = document.getElementById(parseInt(id) + 1).innerHTML;
                      let currentValue = document.getElementById(id).innerHTML;

                      document.getElementById(parseInt(id) + 1).innerHTML = currentValue;
                      document.getElementById(id).innerHTML = moveToOldValue;

                    })(this);">
                      &darr; Move Row Down
                    </button>
                <button class="cms-button" onclick="(function(){ document.getElementById(${index}).remove() })();">DELETE ROW</button>
                <div class="container-fluid full-container">
                  <div class="row">
                    <div id=${index + "cb"} class="col-md-6 img-col-6 cms-back-img-col" contenteditable="true" onkeydown="(function(event) { console.log('Code: ', event.keyCode); if(event.keyCode !== 8) { event.preventDefault(); alert('This is an image column. It cannot accept text. Please Upload Image.')} })(event);">
                      Insert background image here...
                    </div>
                    <div class="col-md-6 cms-col col-text-right" contenteditable="true">
                      
                    </div>
                  </div>  
                </div>
              </section>
              `,
      ]);
    }

    setDisplayContent(true);
    setDisplayHtmlRow(false);
    window.scrollTo(0, document.body.scrollHeight);
  }

  const saveId = () => {
    currentId = document.activeElement.id;
    console.log("currentId: ", currentId);
  }

  const fileSelectedHandler = async (event) => {
    console.log(
      "fileSelectedHandler fired!",
      event.target.files,
      event.target.files[0],
      event.target.files[0].name,
      databaseName
    );

    let mySelectedFile = event.target.files[0];
    axios.defaults.baseURL = LOCALHOST;

    if (mySelectedFile) {
      const fd = new FormData();
      fd.append("file", mySelectedFile, mySelectedFile.name);

      console.log("useEffect fired!");
      axios.defaults.baseURL = LOCALHOST;

      console.log(window.location.href);
      let urlElements = window.location.href.split("=" || "#");

      console.log("urlElements: ", urlElements);

      setFtbNum(urlElements[1]);
      setDatabaseName(urlElements[2]);
      urlElements[3] = urlElements[3].slice(0, urlElements[3].length - 2);
      console.log(urlElements[1], urlElements[2], urlElements[3]);

      axios.get(`api/database/${urlElements[2]}/${urlElements[3]}`)
        .then((res) => {
          console.log(res);

          axios.post(`/api/upload/file/${databaseName}`, fd, {
              onUploadProgress: (progressEvent) => {
                console.log(
                  "Upload Progress: " +
                  Math.round((progressEvent.loaded / progressEvent.total) * 100) +
                  "%"
                );
              }
            })
            .then((res) => {
              console.log(res, res.data.path);

              // this will only work in the live site!!
              let path = res.data.path.split("/");

              path = `https://cmseditor.aaronrich.com/${path[1] + "/" + path[2]}`;
              console.log("path: ", path);

              if (currentId[currentId.length - 1] === "c" || (currentId[currentId.length - 2] + currentId[currentId.length - 1]) === "c2") {
                document.getElementById(currentId).innerHTML = `<img class="img-fluid" src="${path}" />`;
              } else if (currentId[currentId.length - 1] === "b") {
                document.getElementById(currentId).innerHTML = `<div class="col-back-img" style="width: 100%; height: 100%; background-image: url('${path}'); background-size: cover;" />`;
              } else {
                setImgURL(path);
                setDisplayUploadButtion(false);
                alert("Click the location in the document you would like the image to go, then click the green 'Insert Image' in the top of the editor.");
              }

            })
            .catch(error => {
              if (error.response.status == 413) {
                alert("Sorry, that image file is too large. Please upload a file smaller than 900 KBs.");
              }
            });
        })
        .catch((err) => console.log(err));


    }
  };

  const fileSelectedHandler2 = async (event) => {
    console.log(
      "fileSelectedHandler fired!",
      event.target.files,
      event.target.files[0],
      event.target.files[0].name,
      databaseName
    );

    let mySelectedFile = event.target.files[0];
    axios.defaults.baseURL = LOCALHOST;

    if (mySelectedFile) {
      const fd = new FormData();
      fd.append("file", mySelectedFile, mySelectedFile.name);

      console.log("useEffect fired!");
      axios.defaults.baseURL = LOCALHOST;

      console.log(window.location.href);
      let urlElements = window.location.href.split("=" || "#");

      console.log("urlElements: ", urlElements);

      setFtbNum(urlElements[1]);
      setDatabaseName(urlElements[2]);
      urlElements[3] = urlElements[3].slice(0, urlElements[3].length - 2);
      console.log(urlElements[1], urlElements[2], urlElements[3]);

      axios.get(`api/database/${urlElements[2]}/${urlElements[3]}`)
        .then((res) => {
          console.log(res);
          
        // new file path for uploads on all sites
          axios.post(`/api/upload/file/${databaseName}`, fd, {
              onUploadProgress: (progressEvent) => {
                console.log(
                  "Upload Progress: " +
                  Math.round((progressEvent.loaded / progressEvent.total) * 100) +
                  "%"
                );
              }
            })
            .then((res) => {
              console.log(res, res.data.path);

              // this will only work in the live site!!
              let path = res.data.path.split("/");

              path = `https://cmseditor.aaronrich.com/${path[1] + "/" + path[2]}`;
              console.log("path: ", path);

              prompt("This is a link to your file: ", path);

              // alert(`This is a link to your file: \n\n${path} \n\nCopy the link, highlight the text in the document you would like to link to your file, click the link icon in the toolbar, paste the link into the pop-up.`)

              // setFileURL(path);
              // setDisplayFileUploadButtion(false);  
              // alert("Highlight the text in the document you would like the file to link to, then click 'Insert File' in the top right corner of the editor.");            
            })
            .catch(error => {
              if (error.response.status == 413) {
                alert("Sorry, that image file is too large. Please upload a file smaller than 900 KBs.");
              }
            });
        })
        .catch((err) => console.log(err));


    }
  };

  function handleClear() {
    setHtml([]);
  }

  async function handleSaveChanges() {
    console.log(html);

    document.querySelectorAll("section").forEach(s => s.removeAttribute("id"));
    document.querySelectorAll(".cms-button").forEach(b => b.remove());
    document.querySelectorAll(".up-button").forEach(b => b.remove());
    document.querySelectorAll(".down-button").forEach(b => b.remove());
    document.querySelectorAll(".cms-img-col").forEach(d => d.removeAttribute("id"));
    document.querySelectorAll(".cms-back-img-col").forEach(d => d.removeAttribute("id"));
    let combinedHtml = document.getElementById("page-html").innerHTML;
    console.log(combinedHtml);

    axios.defaults.baseURL = LOCALHOST;

    combinedHtml = combinedHtml.replace(/'/g, "''");
    combinedHtml = combinedHtml.replace(
      /<ul>/g,
      '<ul style="padding-left: 20px;">'
    );
    combinedHtml = combinedHtml.replace(
      /<ol>/g,
      '<ol style="padding-left: 20px;">'
    );
    combinedHtml = combinedHtml.replace(/&nbsp;/g, " ");
    combinedHtml = combinedHtml.replace(/contenteditable="true"/g, "");

    console.log(combinedHtml);

    console.log("useEffect fired!");
    axios.defaults.baseURL = LOCALHOST;

    console.log(window.location.href);
    let urlElements = window.location.href.split("=" || "#");

    console.log("urlElements: ", urlElements);

    setFtbNum(urlElements[1]);
    setDatabaseName(urlElements[2]);
    urlElements[3] = urlElements[3].slice(0, urlElements[3].length - 2);
    console.log(urlElements[1], urlElements[2], urlElements[3]);

    axios.get(`api/database/${urlElements[2]}/${urlElements[3]}`)
      .then((res) => {
        console.log(res);

        axios.put("/api/update/content", { ftbNum, combinedHtml })
          .then((res) => {
            console.log(res);

            alert("Save successful!");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            // handleSaveChanges();

            alert(
              "Save unsuccessful. Please wait 10 seconds and try to save again. Try this 3 times or until you see a 'Save successful!' alert. \n\nIf the error persists, please contact Aaron Rich Marketing for help. There may be a storage limit on your database that we need to increase."
            );
          });
      })
      .catch((err) => console.log(err));
  }

  function setMyHtml() {
    console.log(html);

    let combinedHtml = document.getElementById("page-html").innerHTML;

    combinedHtml = combinedHtml.replace(
      /<ul>/g,
      '<ul style="padding-left: 20px;">'
    );
    combinedHtml = combinedHtml.replace(
      /<ol>/g,
      '<ol style="padding-left: 20px; ">'
    );
    combinedHtml = combinedHtml.replace(/&nbsp;/g, " ");

    console.log(combinedHtml);
    return combinedHtml;
  }

  function fontSizeChanger(event) {
    var value = event.target.value;
    console.log(value);
    if (value == 1 || value == 2 || value == 3 || value == 4 || value == 5 || value == 6 || value == 7) {
      document.execCommand("fontSize", false, value);

    }
  }

  function richTextEditor(param) {
    console.log("richTextEditor function fired!", param, imgURL, fileURL);

    if (param == "p" || param == "h1" || param == "h2" || param == "h3" || param == "h4" || param == "h5" || param == "h6") {
      document.execCommand("formatBlock", false, param);
      // if(param === "p") { document.execCommand("fontSize", false, 3); }
      // else if(param === "h1") { document.execCommand("fontSize", false, 7); }
      // else if(param === "h2") { document.execCommand("fontSize", false, 6); }
      // else if(param === "h3") { document.execCommand("fontSize", false, 5); }
      // else if(param === "h4") { document.execCommand("fontSize", false, 4); }
      // else if(param === "h5") { document.execCommand("fontSize", false, 3); }
      // else if(param === "h6") { document.execCommand("fontSize", false, 3); }
    }

    else if (param == "createlink") {
      let url = prompt("Enter the link here: ", "https://");
      document.execCommand(param, false, url);
    }

    else if (param == "insertFile") {
      document.execCommand("createLink", false, fileURL);
      setDisplayFileUploadButtion(true);
    }

    else if (param == "insertimage") {
      document.execCommand('insertHTML', false, `<img class="img-fluid" src="${imgURL}" />`);
      setDisplayUploadButtion(true);
    }

    else if (param == "insertText") {
      let text = prompt("Paste your text here: ", "");
      document.execCommand('insertText', false, text);
    }

    else if (param == "insertIframe") {
      let iframe = prompt("Paste your iframe here: ", "");
      document.execCommand('insertHTML', false, iframe);
    }

    else if (param == "twoColumns") {
      document.execCommand('insertHTML', false, '<div class="container pad-container">\n<div class="row">\n<div class="col-md-6 cms-col" contenteditable="true">\n<p>Insert here...</p></div>\n<div class="col-md-6 cms-col" contenteditable="true">\n<p>Insert here...</p>\n</div>\n</div>\n</div>');
    }

    else if (param == "threeColumns") {
      document.execCommand('insertHTML', false, '<div class="container pad-container">\n<div class="row">\n<div class="col-md-4 cms-col" contenteditable="true">\n<p>Insert here...</p></div>\n<div class="col-md-4 cms-col" contenteditable="true">\n<p>Insert here...</p>\n</div>\n<div class="col-md-4 cms-col" contenteditable="true">\n<p>Insert here...</p>\n</div>\n</div>\n</div>');
    }

    else document.execCommand(param, false, null);
  }

  function handlePaste(event) {
    event.preventDefault();
    let text = event.clipboardData.getData('Text');
    document.execCommand('insertText', false, text);
  }

  return (
    <div id="page" style={styles.page}>
      {showFiles
        ? <div style={styles.topImgContainerTop}>
            <button onClick={() => setShowFiles(!showFiles)} style={{ marginBottom: '20px' }}>Close</button>
            <div style={styles.topImgContainer}>
              {files.map(e =>
                <div style={{ width: '24%', wordBreak: 'break-all' }}>
                  <img className="img-fluid" src={`https://cmseditor.aaronrich.com/${databaseName}/${e}`} />
                  <p style={{ fontSize: '10px', textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>{`https://cmseditor.aaronrich.com/${databaseName}/${e}`}</p>
                </div>
              )}
            </div>
          </div>
        : null
      }
      {displayHtmlRow ? (
        <div style={styles.topHtmlContainer}>
          <div style={styles.topAddRowContainer}>
            <form style={styles.topHtmlInputContainer}>
              <label style={styles.topAddHtmlLabel}>
                Page HTML Text:
                <textarea
                  style={styles.topHtmlInput}
                  type="text"
                  onChange={(e) => setHtml([e.target.value])}
                >
                  {setMyHtml()}
                </textarea>
              </label>
              <Button onClick={() => {
                setDisplayHtmlRow(!displayHtmlRow);
                setDisplayContent(!displayContent);
              }}
                style={styles.saveHtmlButton}>
                Save HTML Changes
              </Button>
            </form>
          </div>
        </div>
      ) : (
        <div style={styles.addHtmlContainer}>
          <Button onClick={handleClear} style={styles.clearButton}>
            Clear Page
          </Button>
          <Button onClick={() => {
            setDisplayHtmlRow(!displayHtmlRow);
            setDisplayContent(!displayContent);
          }}
            style={styles.htmlDisplayButton}>
            See HTML
          </Button>
          <Button onClick={handleSaveChanges} style={styles.saveButton}>
            Save & Publish
          </Button>
        </div>
      )}

      {displayContent ? (
        <div style={styles.toolbar}>
          <div style={styles.toolbarGroup}>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => { richTextEditor("undo") }}
            >
              <i className="fa fa-undo"></i>
            </button>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => { richTextEditor("redo") }}
            >
              <i className="fa fa-repeat"></i>
            </button>
          </div>

          <div style={styles.toolbarGroup}>
            {/* <label style={styles.toolbarGroupItem} for="font-size">Font size:</label> */}

            {/* <select name="font-size" id="font-size" value={sizeSelectValue} onChange={fontSizeChanger}>
              <option value={0}>Font Sizes:</option>
              <option value={1}>8</option>
              <option value={2}>10</option>
              <option value={3}>12</option>
              <option value={4}>14</option>
              <option value={5}>18</option>
              <option value={6}>24</option>
              <option value={7}>36</option>
            </select> */}

            {/* maybe onClick, clear the selected option */}

            <select name="headers" id="headers" value={headerSelectValue} onChange={(event) => richTextEditor(event.target.value)}>
              <option value={0}>Text Style (P - H6):</option>
              <option value={"p"}>Paragraph</option>
              <option value={"h1"} style={{ fontSize: "40pt" }}>Header 1</option>
              <option value={"h2"} style={{ fontSize: "35pt" }}>Header 2</option>
              <option value={"h3"} style={{ fontSize: "30pt" }}>Header 3</option>
              <option value={"h4"} style={{ fontSize: "25pt" }}>Header 4</option>
              <option value={"h5"} style={{ fontSize: "20pt" }}>Header 5</option>
              <option value={"h6"} style={{ fontSize: "15pt" }}>Header 6</option>
            </select>
          </div>

          <div style={styles.toolbarGroup}>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => { richTextEditor("bold") }}
            >
              <i className="fa fa-bold"></i>
            </button>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => { richTextEditor("italic") }}
            >
              <i className="fa fa-italic"></i>
            </button>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => { richTextEditor("underline") }}
            >
              <i className="fa fa-underline"></i>
            </button>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => { richTextEditor("strikeThrough") }}
            >
              <i className="fa fa-strikethrough"></i>
            </button>
          </div>

          <div style={styles.toolbarGroup}>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => { richTextEditor("justifyLeft") }}
            >
              <i className="fa fa-align-left"></i>
            </button>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => { richTextEditor("justifyCenter") }}
            >
              <i className="fa fa-align-center"></i>
            </button>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => { richTextEditor("justifyRight") }}
            >
              <i className="fa fa-align-right"></i>
            </button>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => { richTextEditor("justifyFull") }}
            >
              <i className="fa fa-align-justify"></i>
            </button>
          </div>

          {/* indent and outdent break everything. Need to fix before adding. */}

          {/* <div style={styles.toolbarGroup}>
          <button
            style={styles.toolbarGroupItem}
            Click={(e) => {e.preventDefault(); richTextEditor("outdent")}}
          >
            <i className="fa fa-outdent"></i>
          </button>
          <button
            style={styles.toolbarGroupItem}
            Click={(e) => {e.preventDefault(); richTextEditor("indent")}}
          >
            <i className="fa fa-indent"></i>
          </button>
        </div> */}

          <div style={styles.toolbarGroup}>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => { richTextEditor("insertUnorderedList") }}
            >
              <i className="fa fa-list-ul"></i>
            </button>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => { richTextEditor("insertOrderedList") }}
            >
              <i className="fa fa-list-ol"></i>
            </button>
          </div>
          <div style={styles.toolbarGroup}>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => { richTextEditor("twoColumns") }}
            >
              <i className="far fa-columns"></i>
            </button>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => { richTextEditor("threeColumns") }}
            >
              <i className="far fa-square-full"></i>
              <i className="far fa-square-full"></i>
              <i className="far fa-square-full"></i>
            </button>
          </div>

          {/* <div style={styles.toolbarGroup}>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => {richTextEditor("superscript")}}
            >
              <i className="fa fa-superscript"></i>
            </button>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => {richTextEditor("subscript")}}
            >
              <i className="fa fa-subscript"></i>
            </button>
          </div> */}

          <div style={styles.toolbarGroup}>
            <button
              style={styles.toolbarGroupItem}
              onClick={() => { richTextEditor("createlink") }}
            >
              <i className="fa fa-link"></i>
            </button>
            <button
              style={styles.toolbarGroupItem}
              data-command="unlink"
              onClick={() => { richTextEditor("unlink") }}
            >
              <i className="fa fa-unlink"></i>
            </button>
          </div>

          <div className="d-flex">

            {displayUploadButton
              ? <div style={styles.toolbarGroup}>
                <label
                  htmlFor="imageUpload"
                  onMouseOver={saveId}
                  style={{
                    margin: "5px 0",
                    color: "black",
                    cursor: "pointer",
                    border: "1px solid black",
                  }}>
                  <p style={{ margin: "0 5px", fontWeight: 500, }}>Upload Image</p>
                </label>
                <input
                  id="imageUpload"
                  style={{ display: "none" }}
                  type="file"
                  onChange={fileSelectedHandler}
                />
              </div>
              : <div style={styles.toolbarGroup}>
                <button
                  style={styles.toolbarGroupItem}
                  onClick={() => { richTextEditor("insertimage") }}
                >
                  {/* <i className='fa fa-image'></i> */}
                  <button style={{
                    margin: "5px 0",
                    backgroundColor: "rgb(22, 166, 182)",
                    color: "white",
                    border: 0,
                    fontWeight: 500,
                  }}>Insert Image</button>
                </button>
              </div>
            }

            <div style={styles.toolbarGroup}>
              <button
                style={{
                  padding: "0 5px",
                  margin: 0,
                  color: "black",
                  fontWeight: "500",
                  border: "1px solid black",
                  backgroundColor: "transparent",
                }}
                onClick={() => { richTextEditor("insertText") }}
              >Paste Text</button>
            </div>

            <div style={styles.toolbarGroup}>
              <button
                style={{
                  padding: "0 5px",
                  margin: 0,
                  color: "black",
                  fontWeight: "500",
                  border: "1px solid black",
                  backgroundColor: "transparent",
                }}
                onClick={() => { richTextEditor("insertIframe") }}
              >Insert iframe/HTML</button>
            </div>

            <div style={styles.toolbarGroup}>
              <label
                htmlFor="fileUpload"
                onMouseOver={saveId}
                style={{
                  margin: "5px 0",
                  color: "black",
                  cursor: "pointer",
                  border: "1px solid black",
                }}>
                <p style={{ margin: "0 5px", fontWeight: 500, }}>Upload File</p>
              </label>
              <input
                id="fileUpload"
                style={{ display: "none" }}
                type="file"
                onChange={fileSelectedHandler2}
              />
            </div>

            <div style={styles.toolbarGroup}>
              <button
                style={{
                  padding: "0 5px",
                  margin: 0,
                  color: "black",
                  fontWeight: "500",
                  border: "1px solid black",
                  backgroundColor: "transparent",
                }}
                onClick={() => seeFiles()}
              >See Files</button>
            </div>

            {/* {displayFileUploadButton
              ? <div style={styles.toolbarGroup}>
                  <label 
                    htmlFor="fileUpload" 
                    onMouseOver={saveId}
                    style={{
                      margin: "5px 0",                   
                      color: "black",
                      cursor: "pointer",
                      border: "1px solid black",
                    }}>
                      <p style={{margin: "0 5px", fontWeight: 500,}}>Upload File</p>
                  </label> 
                  <input
                    id="fileUpload"
                    style={{display: "none"}}
                    type="file"
                    onChange={fileSelectedHandler2}
                  />
                </div>
              : <div style={styles.toolbarGroup}>
                  <button
                    style={styles.toolbarGroupItem}
                          onClick={() => {richTextEditor("insertFIle")}}
                  >
                    <button style={{
                        margin: "0 5px",
                        backgroundColor: "rgb(22, 166, 182)",
                        color: "white",
                        border: 0,
                        fontWeight: 500,
                      }}>Insert File</button>
                  </button>
                </div>
            } */}

          </div>

        </div>
      ) : null}


      <div>
        <div
          id="page-html"
          className="container-fluid full-container"
          style={styles.pageHtml}
          onPaste={(event) => handlePaste(event)}
          dangerouslySetInnerHTML={{
            __html: `${html.map((e, i) => (
              console.log(html),
              console.log(e),
              e
            ))
              .join(" ")}`,
          }}
        ></div>
      </div>

      <div style={styles.addRowButtonContainer}>
        {displayAddRowBox
          ? <div style={styles.addRowBox}>
            <h4 style={{ margin: "20px 0", color: "black", fontWeight: 700 }}>Choose a Row Type:</h4>
            <div style={styles.addRowTypeCont}>
              <div
                style={styles.addIntroRowCont}
                onClick={() => {
                  setDisplayAddRowBox(displayAddRowBox => !displayAddRowBox);
                  setDisplayPlusButton(displayPlusButton => !displayPlusButton);
                  setRowType("intro");
                  addRow(1, "intro");
                }}>
                <div style={styles.addIntroRowIl}>
                  <h5 style={{ color: "black", fontWeight: 700 }}>Introduction</h5>
                </div>
              </div>
              <div
                style={styles.addPaddedRowCont}
                onClick={() => {
                  setDisplayAddRowBox(displayAddRowBox => !displayAddRowBox);
                  setDisplayAddRowBox2(displayAddRowBox2 => !displayAddRowBox2);
                  setRowType("pad");
                }}>
                <div style={styles.addPaddedRowIl}>
                  <h5 style={{ color: "black", fontWeight: 700 }}>Space Around</h5>
                </div>
              </div>
              <div
                style={styles.addFullRowCont}
                onClick={() => {
                  setDisplayAddRowBox(displayAddRowBox => !displayAddRowBox);
                  setDisplayAddRowBox2(displayAddRowBox2 => !displayAddRowBox2);
                  setRowType("full");
                }}>
                <div style={styles.addFullRowIl}>
                  <h5 style={{ color: "black", fontWeight: 700 }}>No Space Around</h5>
                </div>
              </div>
            </div>
          </div>
          : null
        }

        {displayAddRowBox2
          ? <div style={styles.addRowBox2}>
            <h4 style={{ margin: "20px 0", color: "black", fontWeight: 700 }}>Choose Column Type:</h4>
            <div style={styles.addRowTypeCont2}>
              <div
                style={styles.addColCont}
                onClick={() => {
                  addRow(1, "text-only");
                  setDisplayAddRowBox(false);
                  setDisplayAddRowBox2(false);
                  setDisplayPlusButton(true);
                }}
              >
                <div style={styles.addColIl}>
                  <h5 style={{ width: "100%", textAlign: "center", color: "black", fontWeight: 700 }}>Text Only</h5>
                </div>
              </div>
              <div
                style={styles.addColCont}
                onClick={() => {
                  addRow(1, "img-only");
                  setDisplayAddRowBox(false);
                  setDisplayAddRowBox2(false);
                  setDisplayPlusButton(true);
                }}
              >
                <div style={styles.addColIl}>
                  <h5 style={{ width: "100%", textAlign: "center", color: "black", fontWeight: 700 }}>Image Only</h5>
                </div>
              </div>
              <div
                style={styles.addColCont}
                onClick={() => {
                  addRow(2, "text-img");
                  setDisplayAddRowBox(false);
                  setDisplayAddRowBox2(false);
                  setDisplayPlusButton(true);
                }}
              >
                <div style={styles.addColIl}>
                  <h5 style={styles.addColLeft2}>Text</h5>
                  <h5 style={styles.addColRight2}>Image</h5>
                </div>
              </div>
              <div
                style={styles.addColCont}
                onClick={() => {
                  addRow(2, "img-text");
                  setDisplayAddRowBox(false);
                  setDisplayAddRowBox2(false);
                  setDisplayPlusButton(true);
                }}
              >
                <div style={styles.addColIl}>
                  <h5 style={styles.addColLeft2}>Image</h5>
                  <h5 style={styles.addColRight2}>Text</h5>
                </div>
              </div>
            </div>

            <div style={styles.addRowTypeCont2}>
              <div
                style={styles.addColCont}
                onClick={() => {
                  addRow(2, "text-text");
                  setDisplayAddRowBox(false);
                  setDisplayAddRowBox2(false);
                  setDisplayPlusButton(true);
                }}
              >
                <div style={styles.addColIl}>
                  <h5 style={styles.addColLeft2}>Text</h5>
                  <h5 style={styles.addColRight2}>Text</h5>
                </div>
              </div>
              <div
                style={styles.addColCont}
                onClick={() => {
                  addRow(2, "img-img");
                  setDisplayAddRowBox(false);
                  setDisplayAddRowBox2(false);
                  setDisplayPlusButton(true);
                }}
              >
                <div style={styles.addColIl}>
                  <h5 style={styles.addColLeft2}>Image</h5>
                  <h5 style={styles.addColRight2}>Image</h5>
                </div>
              </div>
              <div
                style={styles.addColCont}
                onClick={() => {
                  addRow(2, "text-backImg");
                  setDisplayAddRowBox(false);
                  setDisplayAddRowBox2(false);
                  setDisplayPlusButton(true);
                }}
              >
                <div style={styles.addColIl}>
                  <h5 style={styles.addColLeft2}>Text</h5>
                  <h5 style={styles.addColRight2}>Back. Image</h5>
                </div>
              </div>
              <div
                style={styles.addColCont}
                onClick={() => {
                  addRow(2, "backImg-text");
                  setDisplayAddRowBox(false);
                  setDisplayAddRowBox2(false);
                  setDisplayPlusButton(true);
                }}
              >
                <div style={styles.addColIl}>
                  <h5 style={styles.addColLeft2}>Back. Image</h5>
                  <h5 style={styles.addColRight2}>Text</h5>
                </div>
              </div>
            </div>

          </div>
          : null
        }

        {displayPlusButton
          ? <Button
            onClick={() => {
              setDisplayAddRowBox(displayAddRowBox => !displayAddRowBox);
              setDisplayPlusButton(displayPlusButton => !displayPlusButton);
            }}
            style={styles.addRowDisplayButton}
          >
            +
          </Button>
          :
          <Button
            onClick={() => {
              setDisplayAddRowBox(false);
              setDisplayAddRowBox2(false);
              setDisplayPlusButton(displayPlusButton => !displayPlusButton);
            }}
            style={styles.addRowDisplayButton}
          >
            -
          </Button>
        }

      </div>

    </div>
  );
}

export default ContentEditor;

let styles = useStyles({
  page: {
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 1px 2px 1px rgba(0,0,0,.08), 0 3px 6px rgba(0,0,0,.08)",
  },
  pageHtml: {
    width: "auto",
    display: "flex",
    flexDirection: "column",
    margin: 0,
    outline: 0,
  },
  addHtmlContainer: {
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    // backgroundColor: "#cfcdcc",
    backgroundColor: "#f7f5f3",
    boxSizing: "border-box",
    position: "sticky",
    top: 0,
    zIndex: 90,
  },
  addRowButtonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    backgroundColor: "#fcfcfc",
    boxSizing: "border-box",
    position: "sticky",
    marginTop: 290,
    bottom: 0,
    zIndex: 10,
  },
  addRowBox: {
    width: "90%",
    paddingBottom: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "-270px 15% 20px",
    backgroundColor: "rgb(247, 245, 243)",
    borderRadius: 6,
    boxShadow: "0 0 7px #cfcdcc",
  },
  addRowBox2: {
    width: "95%",
    paddingBottom: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "-270px 15% 20px",
    backgroundColor: "rgb(247, 245, 243)",
    borderRadius: 6,
    boxShadow: "0 0 7px #cfcdcc",
  },
  addRowDisplayButton: {
    width: 60,
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 30,
    fontWeight: 300,
    color: "white",
    backgroundColor: "#16a6b6",
    border: 0,
    borderRadius: "60%",
    paddingBottom: 12,
    // position: "absolute",
    // bottom: 90,
  },
  addRowTypeCont: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    marginTop: 20,
  },
  addRowTypeCont2: {
    display: "flex",
    width: "90%",
    justifyContent: "space-around",
    marginTop: 20,
  },
  addIntroRowCont: {
    backgroundColor: "white",
    flexDirection: "column",
    alignItems: "center",
    padding: 30,
    boxShadow: "0 0 7px #cfcdcc",
  },
  addIntroRowIl: {
    backgroundColor: "#cfcdcc",
    cursor: "pointer",
    padding: 10,
  },
  addPaddedRowCont: {
    backgroundColor: "white",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    boxShadow: "0 0 7px #cfcdcc",
  },
  addPaddedRowIl: {
    backgroundColor: "#cfcdcc",
    cursor: "pointer",
    padding: 15,
    marginTop: 5,
  },
  addFullRowCont: {
    backgroundColor: "white",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0 0 7px #cfcdcc",
  },
  addFullRowIl: {
    backgroundColor: "#cfcdcc",
    cursor: "pointer",
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: 15,
  },
  addColCont: {
    backgroundColor: "white",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0 0 7px #cfcdcc",
    width: 150,
    height: 75,
  },
  addColIl: {
    backgroundColor: "white",
    cursor: "pointer",
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  addColLeft2: {
    width: "50%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "1px solid black",
    margin: 0,
    textAlign: "center",
    color: "black",
    fontWeight: 700
  },
  addColRight2: {
    width: "50%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 0,
    textAlign: "center",
    color: "black",
    fontWeight: 700
  },
  addColLeft3: {
    width: "33.3%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "1px solid black",
    margin: 0,
    color: "black"
  },
  addColRight3: {
    width: "33.3%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 0,
    color: "black"
  },
  addColLeft4: {
    width: "25%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "1px solid black",
    margin: 0,
    color: "black"
  },
  addColRight4: {
    width: "25%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 0,
    color: "black"
  },
  clearButton: {
    padding: "5px 20px",
    fontSize: 14,
    fontWeight: 700,
    color: "white",
    backgroundColor: "#fa3b34",
    // backgroundColor: "rgb(22, 166, 182)",
    border: 0,
    borderRadius: 6,
  },
  htmlDisplayButton: {
    padding: "5px 20px",
    fontSize: 14,
    fontWeight: 700,
    color: "white",
    backgroundColor: "#16a6b6",
    border: 0,
    borderRadius: 6,
    bottom: 0,
    marginLeft: 18,
  },
  saveButton: {
    padding: "5px 20px",
    fontSize: 14,
    fontWeight: 700,
    color: "white",
    // backgroundColor: "#fa3b34",
    backgroundColor: "rgb(22, 166, 182)",
    border: 0,
    borderRadius: 6,
  },
  saveHtmlButton: {
    // width: "100%",
    padding: "6px 20px",
    fontSize: 14,
    fontWeight: 700,
    color: "white",
    backgroundColor: "#16a6b6",
    border: 0,
    borderRadius: 6,
    bottom: 0,
    marginTop: 10,
  },
  toolbar: {
    minHeight: 50,
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    border: "1px #edebe9 solid",
    boxShadow: "0 1px 8px #edebe9",
    padding: 5,
    position: "sticky",
    top: 50,
    zIndex: 90,
  },
  toolbarGroup: {
    margin: "0 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  toolbarGroupItem: {
    margin: 5,
    color: "black",
    cursor: "pointer",
    backgroundColor: "#fff",
    border: 0,
    padding: 0
  },
  topAddHtmlLabel: {
    width: "100%",
    height: "100%",
    textAlign: "center",
  },
  topImgContainerTop: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: 20,
    backgroundColor: "#edebe9",
    zIndex: 100,
  },
  topImgContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexWrap: 'wrap',
    justifyContent: "space-between",
    alignItems: "center",
  },
  topHtmlContainer: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    backgroundColor: "#edebe9",
    boxSizing: "border-box",
    position: "sticky",
    top: 0,
    zIndex: 100,
  },
  topHtmlInputContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  topHtmlInput: {
    width: "100%",
    height: "95%",
  },
  topAddRowContainer: {
    width: "100%",
    height: "100%",
    zIndex: 10,
  },
});
